import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import call from "../../assets/Images/png/Group 9278.png";
import chatbox from "../../assets/Images/png/Group 9279.png";
import video from "../../assets/Images/png/Group 9280.png";
import Swal from "sweetalert2"; // Import SweetAlert2
import withReactContent from "sweetalert2-react-content";



import {
  Helppagecontent,
  Helppagetextone,
  Helppagetexttwo,
  Imagecall,
  Imagecall1,
  Imagecircleone,
  Imagecirclethree,
  Imagecircletwo,
  Images,
  Imagetext
} from "./Helppage.styled";
import { AuthContext } from "../../consts/AuthContext";
import axiosInstance from "../../consts/axiosInstance";

const colors = [
  "linear-gradient(45deg, #14567B 0%, #7AC9C2 100%)",
  "linear-gradient(45deg, #F36C9E 0%, #F090F5 100%)",
  "linear-gradient(45deg, #FE5D53 0%, #F0971A 100%)",
];
const SwalAlert = withReactContent(Swal); // Enhance SweetAlert2 for React

function Helppage() {
  const navigation = useNavigate();
  const { state } = useLocation();
  const homeid = JSON.parse(localStorage.getItem("homeid") || "[]");
  const [authUser, setAuthUser] = useContext(AuthContext);



  // Function to show Swal alert
  const showSwalAlert = (message) => {
    SwalAlert.fire({
      title: "",
      text: message,
      icon: "info",
      confirmButtonText: "OK",
      confirmButtonColor: "#3085d6",
    });
  };

  const showCallAlert = (message , title) => {
    SwalAlert.fire({
      title: title,
      text: message,
      confirmButtonText: "OK",
      confirmButtonColor: "#3085d6",
    })
  }

  // const { id } = state;
  const id = { state };

  const closefunction = () => {
    id(false);
    // console.log("id", state);
  };

  const callFunction = async () => {
    // console.log('authUser', authUser)
    try {
      const response = await axiosInstance.post('clickToCall', {
        'mob1': authUser?.user?.mob1
      })
      console.log('response.data', response.data)
      showCallAlert('Our agent will connect with you shortly.' , 'Success')
    } catch (error) {
      // console.log('error', error)
      showCallAlert('Call failed, Please try again later' , 'Call Failed')
    }
  }
  const openSkypeCall = () => {
    const skypeUsername = 'https://join.skype.com/tACB2wgf2zn9%22'; // Replace with the Skype username
    const url = `skype:${skypeUsername}?call&video=true`; // Skype URL schema for a video call

    // Check if the browser supports the Skype URL
    const isSkypeSupported = () => {
      const a = document.createElement('a');
      a.href = url;
      return a.protocol === 'skype:';
    };

    if (isSkypeSupported()) {
      window.open(url); // Open Skype call
    } else {
      alert('Skype is not supported in this browser or is not installed on your system.');
    }
  };
  const openWhatsApp = () => {
    const phoneNumber = '8111852100'; // Replace with the recipient's phone number (with country code, e.g., '911234567890' for India)
    const message = encodeURIComponent('Hello, I would like to connect with you!'); // Message to send
    const url = `https://wa.me/+91${phoneNumber}?text=${message}`; // WhatsApp API URL for messaging

    window.open(url, '_blank'); // Open WhatsApp in a new tab
  };
  return (
    // <ModalContainer>
    //     <Modal>
    //         <Overlay>

    <Helppagecontent >
      {/* <Div >
                <MdOutlineClose />
            </Div> */}
      <Helppagetextone>Do you need any help?</Helppagetextone>
      <Helppagetexttwo>
        <p>
          Facing an issue or looking for information? Get the answers and support you’re looking for!
        </p>
      </Helppagetexttwo>

      <Images>
        {homeid === "002" ? (
          <Imagecircleone>
            <Imagecall
            // style={{ background: 'linear-gradient(#529F7F 0%, #1FBEC1 100%)' }}
            >
              <img src={call}></img>
            </Imagecall>
            <Imagetext>Call advisor</Imagetext>
          </Imagecircleone>
        ) : (
          <>
            <Imagecircleone>
              <Imagecall className="cursor-pointer"
                // style={{ background: 'linear-gradient(#529F7F 0%, #1FBEC1 100%)' }}
                onClick={() => callFunction()}
              >
                <img src={call}></img>
              </Imagecall>
              <Imagetext >Call advisor</Imagetext>
            </Imagecircleone>

            <Imagecircletwo>
              <Imagecall1 className="cursor-pointer"
                onClick={() =>
                  openWhatsApp()}              >
                <img src={chatbox}></img>
              </Imagecall1>
              <Imagetext>Chat with a advisor</Imagetext>
            </Imagecircletwo>

            <Imagecirclethree>
              <Imagecall1 className="cursor-pointer"
                onClick={openSkypeCall}>
                <img src={video}></img>

              </Imagecall1>
              <Imagetext>Video chat with a advisor</Imagetext>
            </Imagecirclethree>

          </>
        )}
      </Images>


      {/* <div>
        
        {homeid === "002" ? (
          <Button onClick={() => navigation(-1)}>
            <p>Skip</p> <MdDoubleArrow style={{ margin: "5px" }} />
          </Button>
        ) : (
          <Button
            onClick={() => [
              navigation("/sales/productcard/users", { state: false }),
              closefunction(),
            ]}>
            <p>Skip</p> <MdDoubleArrow style={{ margin: "5px" }} />
          </Button>
        )}
      </div> */}
    </Helppagecontent >

    //         </Overlay>
    //     </Modal>
    // </ModalContainer>
  );
}
export default Helppage;
